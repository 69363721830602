$(document).ready(function () {
    // Initialize the slick slider
    $('.blog-carousel').slick({
        infinite: false,
        draggable: false,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: '.carousel-navigation',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    // Filter for all categories on initial
    $('.blog-carousel').slick('slickFilter', '.blog-category--all');

    // Navigation for blog posts
    $('.blog-category-link').on('click', function () {
        /* 
        We need the name of the category to show 
        only cards with the name in their class property

        Every card in the slider has a class called 'blog-category--all'.
        This is because we need a general class for the 'show all' filter.

        Every card also has its own category.
        Example: blog-category--press
        */
        let category = this.id.replace('blog-category-link--', 'blog-category--');
        // remove any filter
        $('.blog-carousel').slick('slickUnfilter');

        // filter by the new category
        $('.blog-carousel').slick('slickFilter', '.' + category);

        // slide back to the beginning
        $('.blog-carousel').slick('slickGoTo', 0, true);
    });

    // Add navigation to the carousels
    $('.blog-carousel-next').click(function () {
        $('.blog-carousel').slick('slickNext');
    });

    $('.blog-carousel-prev').click(function () {
        $('.blog-carousel').slick('slickPrev');
    });

    // Infos and Updates Page
    // Show all functionality

    $('#blog-category-show-all').click(function(){
        $('#carousel--filtered').addClass('d-none');
        $('#carousel--show-all').removeClass('d-none');
        $('.carousel-navigation').addClass('d-none');
    });
    
    $('.blog-category-links').click(function(){
        $('#carousel--filtered').removeClass('d-none');
        $('#carousel--show-all').addClass('d-none');
        $('.carousel-navigation').removeClass('d-none');
        $('#carousel--filtered').slick('refresh');
    });


});