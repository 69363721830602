// Initial load of scroll animation plugin
sal();

// Initial load of enllax parallax plugin
$(window).enllax();

$(".scroll-to-intro").click(function () {
    $([document.documentElement, document.body]).animate({
        scrollTop: $("#intro-anchor").offset().top - 80
    }, 1000);
    return false;
});

$(".scroll-up-arrow").click(function () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
});

